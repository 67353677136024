.info-bar {
  display: flex;
  justify-content: space-between;
  color: white;
  margin-top: 50px;
  margin-left: 60px;
  margin-right: 60px;
}

.centered-practice-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.exit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  padding: 1em 6em;
  max-width: 8rem;
  background-color: rgb(185, 80, 80);
  color: white;
  border: none;
  border-radius: 5px;
  letter-spacing: 2px;
  font-weight: 700;
  font-family: "Mallanna";
  white-space: nowrap;
  text-align: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
}

.exit-button:hover {
  cursor: pointer;
}

.score-card {
  display: flex;
  justify-content: center;
  font-size: 1em;
  padding: 0.8em 1em;
  width: 8rem;
  background-color: var(--bg-violet);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  color: var(--bg-dark);
  border: none;
  border-radius: 5px;
  letter-spacing: 2px;
  font-weight: 700;
  font-family: "Mallanna";
  white-space: nowrap;
  text-align: center;
  user-select: none;
}

.score-card.score-card-nonmetals {
  background-color: var(--non-metal);
}

.score-card.score-card-alkali-metals {
  background-color: var(--alkali-metal);
}

.score-card.score-card-alkaline-earth-metals {
  background-color: var(--alkaline-earth-metal);
}

.score-card.score-card-metalloids {
  background-color: var(--metalloid);
}

.score-card.score-card-halogens {
  background-color: var(--halogen);
}

.score-card.score-card-transition-metals {
  background-color: var(--transition-metal);
}

.score-card.score-card-post-transition-metals {
  background-color: var(--post-transition-metal);
}

.score-card.score-card-noble-gases {
  background-color: var(--noble-gas);
}

.score-card.score-card-lanthanides {
  background-color: var(--lanthanide);
}

.score-card.score-card-actinides {
  background-color: var(--actinide);
}

.exit-button:hover {
  cursor: pointer;
  background-color: rgb(184, 112, 112);
  transition: background-color ease-in 150ms;
}

.practice-element-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.practice-element {
  --default-color: rgb(255, 179, 249);
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 130px;
  width: 140px;
  border-top: 3px solid var(--default-color);
  border-left: 3px solid var(--default-color);
  border-right: 3px solid var(--default-color);

  box-shadow: 0 10px var(--default-color);
}

.practice-element-nonmetals {
  border-top: 3px solid var(--non-metal);
  border-left: 3px solid var(--non-metal);
  border-right: 3px solid var(--non-metal);
  box-shadow: 0 10px var(--non-metal);
}

.practice-element-alkali-metals {
  border-top: 3px solid var(--alkali-metal);
  border-left: 3px solid var(--alkali-metal);
  border-right: 3px solid var(--alkali-metal);
  box-shadow: 0 10px var(--alkali-metal);
}

.practice-element-alkaline-earth-metals {
  border-top: 3px solid var(--alkaline-earth-metal);
  border-left: 3px solid var(--alkaline-earth-metal);
  border-right: 3px solid var(--alkaline-earth-metal);
  box-shadow: 0 10px var(--alkaline-earth-metal);
}

.practice-element-halogens {
  border-top: 3px solid var(--halogen);
  border-left: 3px solid var(--halogen);
  border-right: 3px solid var(--halogen);
  box-shadow: 0 10px var(--halogen);
}

.practice-element-metalloids {
  border-top: 3px solid var(--metalloid);
  border-left: 3px solid var(--metalloid);
  border-right: 3px solid var(--metalloid);
  box-shadow: 0 10px var(--metalloid);
}

.practice-element-transition-metals {
  border-top: 3px solid var(--transition-metal);
  border-left: 3px solid var(--transition-metal);
  border-right: 3px solid var(--transition-metal);
  box-shadow: 0 10px var(--transition-metal);
}

.practice-element-post-transition-metals {
  border-top: 3px solid var(--post-transition-metal);
  border-left: 3px solid var(--post-transition-metal);
  border-right: 3px solid var(--post-transition-metal);
  box-shadow: 0 10px var(--post-transition-metal);
}

.practice-element-noble-gases {
  border-top: 3px solid var(--noble-gas);
  border-left: 3px solid var(--noble-gas);
  border-right: 3px solid var(--noble-gas);
  box-shadow: 0 10px var(--noble-gas);
}

.practice-element-lanthanides {
  border-top: 3px solid var(--lanthanide);
  border-left: 3px solid var(--lanthanide);
  border-right: 3px solid var(--lanthanide);
  box-shadow: 0 10px var(--lanthanide);
}

.practice-element-actinides {
  border-top: 3px solid var(--actinide);
  border-left: 3px solid var(--actinide);
  border-right: 3px solid var(--actinide);
  box-shadow: 0 10px var(--actinide);
}

.practice-element-symbol {
  color: var(--default-color);
  font-size: 2.3rem;
  font-weight: 700;
  user-select: none;
}

.practice-element-symbol-nonmetals {
  color: var(--non-metal);
}

.practice-element-symbol-alkali-metals {
  color: var(--alkali-metal);
}

.practice-element-symbol-alkaline-earth-metals {
  color: var(--alkaline-earth-metal);
}

.practice-element-symbol-halogens {
  color: var(--halogen);
}

.practice-element-symbol-metalloids {
  color: var(--metalloid);
}

.practice-element-symbol-transition-metals {
  color: var(--transition-metal);
}

.practice-element-symbol-post-transition-metals {
  color: var(--post-transition-metal);
}

.practice-element-symbol-noble-gases {
  color: var(--noble-gas);
}

.practice-element-symbol-lanthanides {
  color: var(--lanthanide);
}

.practice-element-symbol-actinides {
  color: var(--actinide);
}

.practice-element-number {
  position: absolute;
  top: 5%;
  right: 10%;
  font-size: 1.1em;
  user-select: none;
}

.text-input-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.text-input {
  position: relative;
  min-height: 50px;
  min-width: 40%;
  border-radius: 50px;
  font-size: 1.5em;
  padding: 0.4em 1em;
  background-color: var(--bg-purple);
  border: 4px solid rgb(255, 179, 249);
  color: white;
  font-family: "Mallanna";
  font-weight: 700;
  text-align: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
}

.text-input-nonmetals {
  border: 4px solid var(--non-metal);
  color: var(--non-metal);
  caret-color: var(--non-metal);
}

.text-input-alkali-metals {
  border: 4px solid var(--alkali-metal);
  color: var(--alkali-metal);
  caret-color: var(--alkali-metal);
}

.text-input-alkaline-earth-metals {
  border: 4px solid var(--alkaline-earth-metal);
  color: var(--alkaline-earth-metal);
  caret-color: var(--alkaline-earth-metal);
}

.text-input-metalloids {
  border: 4px solid var(--metalloid);
  color: var(--metalloid);
  caret-color: var(--metalloid);
}

.text-input-halogens {
  border: 4px solid var(--halogen);
  color: var(--halogen);
  caret-color: var(--halogen);
}

.text-input-transition-metals {
  border: 4px solid var(--transition-metal);
  color: var(--transition-metal);
  caret-color: var(--transition-metal);
}

.text-input-post-transition-metals {
  border: 4px solid var(--post-transition-metal);
  color: var(--post-transition-metal);
  caret-color: var(--post-transition-metal);
}

.text-input-noble-gases {
  border: 4px solid var(--noble-gas);
  color: var(--noble-gas);
  caret-color: var(--noble-gas);
}

.text-input-lanthanides {
  border: 4px solid var(--lanthanide);
  color: var(--lanthanide);
  caret-color: var(--lanthanide);
}

.text-input-actinides {
  border: 4px solid var(--actinide);
  color: var(--actinide);
  caret-color: var(--actinide);
}

.text-input:focus {
  outline: none;
}

@media only screen and (max-width: 500px) {
  .practice-element {
    height: 90px;
    width: 100px;
  }

  .practice-element-symbol {
    font-size: 1.8em;
  }

  .text-input {
    min-height: 30px;
    min-width: 30%;
    font-size: 1em;
    padding: 0.4em 1em;
  }

  .text-input-container {
    margin-top: 30px;
  }

  .exit-button,
  .score-card {
    width: 3rem;
    max-width: 3rem;
  }

  .score-card {
    padding: 1em 2em;
  }

  .info-bar {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 25px;
  }
}

@media only screen and (max-height: 500px) {
  .info-bar {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 25px;
  }

  .text-input {
    min-height: 30px;
    min-width: 30%;
    font-size: 1em;
    padding: 0.4em 1em;
  }

  .practice-element {
    height: 90px;
    width: 100px;
  }

  .practice-element-symbol {
    font-size: 1.8em;
  }
}

@media only screen and (min-width: 1440px) {
  .practice-element {
    height: 190px;
    width: 200px;
  }

  .practice-element-symbol {
    font-size: 3.5em;
  }

  .text-input {
    min-height: 30px;
    min-width: 30%;
    font-size: 1.9em;
    padding: 0.4em 1em;
  }

  .text-input-container {
    margin-top: 50px;
  }
}
