.alert-container {
  display: flex;
  border: 2px solid rgb(221, 143, 143);
  margin: auto;
  border-radius: 5px;
  position: relative;
  padding: 0.8em 1em;
  margin-top: 40px;
  width: 50vw;
  justify-content: center;
}

.hide {
  display: none;
}

.alert-message {
  color: rgb(221, 143, 143);
  white-space: nowrap;
  text-align: center;
  font-weight: 700;
}

.alert-close-icon > i {
  position: absolute;
  font-size: 1em;
  top: 10px;
  right: 15px;
  color: rgb(221, 143, 143);
}

.alert-close-icon:hover {
  cursor: pointer;
}
