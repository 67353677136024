.select-bar-grid {
  margin-top: 80px;
  margin-left: 70px;
  margin-right: 70px;
  display: grid;
  gap: 1.2rem;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 2;
}

@media only screen and (max-width: 940px) {
  .select-bar-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 5;
  }
}
