.progress {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  gap: 1em;
}

.progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50%;
  height: 2em;
  border-radius: 50px;
  user-select: none;
  font-size: 1em;
  position: relative;
  overflow: hidden;
}

.progress-bar-noble-gases {
  border: 3px solid var(--noble-gas);
  color: var(--noble-gas);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
}

.progress-status {
  display: flex;
  align-items: center;
  margin-top: -7px;
  font-size: 1.2em;
  width: 1em;
  height: 1em;
}

.progress-status:hover {
  font-weight: 700;
  cursor: pointer;
}

.progress-status-noble-gases {
  color: var(--noble-gas);
}

.progress-status-nonmetals {
  color: var(--non-metal);
}

.progress-status-alkali-metals {
  color: var(--alkali-metal);
}

.progress-status-alkaline-earth-metals {
  color: var(--alkaline-earth-metal);
}

.progress-status-metalloids {
  color: var(--metalloid);
}

.progress-status-halogens {
  color: var(--halogen);
}

.progress-status-transition-metals {
  color: var(--transition-metal);
}

.progress-status-post-transition-metals {
  color: var(--post-transition-metal);
}

.progress-status-lanthanides {
  color: var(--lanthanide);
}

.progress-status-actinides {
  color: var(--actinide);
}

.progress-bar::before {
  content: "";
  display: flex;
  align-items: center;
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 5px;
  max-width: calc(100% - 10px);
  min-width: 0%;
  width: calc(var(--width, 0) * 1%);
  border-radius: 50px;
}

.progress-bar-noble-gases::before {
  background-color: var(--noble-gas);
}

.progress-bar-nonmetals::before {
  background-color: var(--non-metal);
}

.progress-bar-alkali-metals::before {
  background-color: var(--alkali-metal);
}

.progress-bar-alkaline-earth-metals::before {
  background-color: var(--alkaline-earth-metal);
}

.progress-bar-metalloids::before {
  background-color: var(--metalloid);
}

.progress-bar-halogens::before {
  background-color: var(--halogen);
}

.progress-bar-transition-metals::before {
  background-color: var(--transition-metal);
}

.progress-bar-post-transition-metals::before {
  background-color: var(--post-transition-metal);
}

.progress-bar-lanthanides::before {
  background-color: var(--lanthanide);
}

.progress-bar-actinides::before {
  background-color: var(--actinide);
}

.progress-bar-nonmetals {
  border: 3px solid var(--non-metal);
  color: var(--non-metal);
}

.progress-bar-alkali-metals {
  border: 3px solid var(--alkali-metal);
  color: var(--alkali-metal);
}

.progress-bar-alkaline-earth-metals {
  border: 3px solid var(--alkaline-earth-metal);
  color: var(--alkaline-earth-metal);
}

.progress-bar-metalloids {
  border: 3px solid var(--metalloid);
  color: var(--metalloid);
}

.progress-bar-halogens {
  border: 3px solid var(--halogen);
  color: var(--halogen);
}

.progress-bar-transition-metals {
  border: 3px solid var(--transition-metal);
  color: var(--transition-metal);
}

.progress-bar-post-transition-metals {
  border: 3px solid var(--post-transition-metal);
  color: var(--post-transition-metal);
}

.progress-bar-lanthanides {
  border: 3px solid var(--lanthanide);
  color: var(--lanthanide);
}

.progress-bar-actinides {
  border: 3px solid var(--actinide);
  color: var(--actinide);
}
