.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  gap: 10px;
}

.toggle-switch-label input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch-label {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--transition-metal);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-switch:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--bg-purple);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .toggle-switch {
  background-color: var(--alkali-metal);
}

input:focus + .toggle-switch {
  box-shadow: 0 0 1px var(--alkali-metal);
}

input:checked + .toggle-switch:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.toggle-switch:before {
  border-radius: 50%;
}

p.toggle-text {
  color: transparent;
}

p.toggle-text:first-child.toggle-text-selected {
  color: var(--transition-metal);
}

p.toggle-text:last-child.toggle-text-selected {
  color: var(--alkali-metal);
}

p.name-selected {
  color: var(--transition-metal);
  font-weight: 900;
}

p.symbol-selected {
  color: var(--alkali-metal);
  font-weight: 900;
}
