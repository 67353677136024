.seen-card {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  padding: 0.8em 1.5em;
  background-color: var(--bg-dark);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  color: var(--bg-light);
  border: none;
  border-radius: 50px;
  letter-spacing: 2px;
  font-weight: 700;
  font-family: "Mallanna";
  white-space: nowrap;
  text-align: center;
  user-select: none;
}
