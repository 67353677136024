.layout-grid {
  display: grid;
  gap: 1.5rem;
  border: 1px solid black;
  grid-template-columns: repeat(18, 1fr);
}

.border {
  border: 1px solid black;
}
