.mastered-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 4em);
}

.mastered-container p {
  color: white;
  font-weight: bolder;
  font-size: 1.25em;
  padding: 1em;
}

.mastered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2em;
}

.mastered-page-container i {
  color: white;
  font-size: 10em;
}

#atom {
  --atom-size: 300px;
  position: relative;
  width: var(--atom-size);
  height: var(--atom-size);
  margin: 10px auto;
  animation: float ease-in-out 3s infinite;
}

.orbit {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: auto;
}

.orbit:before {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 15px solid rgba(178, 241, 254, 0.866);
  border-radius: 50%;
}

.orbit {
  width: 200px;
  height: 200px;
  border: 0;
  transform-style: preserve-3d;
}

.orbit:nth-child(4) {
  transform: rotateY(75deg) rotateX(0deg);
}

.orbit:nth-child(2) {
  transform: rotateY(60deg) rotateX(-54deg);
}

.orbit:nth-child(3) {
  transform: rotateY(60deg) rotateX(54deg);
}

#nucleus {
  --nucleus-color: rgb(255, 139, 126);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: auto;
  background-color: var(--nucleus-color);
  --glow-color: rgba(255, 177, 177, 0.2);
  animation: glow ease-in 10s infinite;
}

@keyframes float {
  0% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(-5px);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 10px 15px rgba(0, 0, 0, 0);
  }

  20% {
    box-shadow: 0 0 5px 10px var(--glow-color);
  }
  40% {
    box-shadow: 0 0 3px 25px var(--glow-color);
  }
  60% {
    box-shadow: 0 0 5px 10px var(--glow-color);
  }
  80% {
    box-shadow: 0 0 3px 25px var(--glow-color);
  }

  100% {
    box-shadow: 0 0 5px 15px rgba(0, 0, 0, 0);
  }
}

.capitalize {
  text-transform: capitalize;
}

.home-button {
  font-size: 0.8em;
  padding: 0.8em 2em;
  border: none;
  border-radius: 5px;
  letter-spacing: 2px;
  font-weight: 700;
  font-family: "Mallanna";
  color: white;
  background-color: var(--bg-green);
  white-space: nowrap;
}

.home-button:hover {
  cursor: pointer;
  background-color: #5b8f8d;
  transition: background-color ease-in 150ms;
}
