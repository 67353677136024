.button-container {
  display: flex;
  position: relative;
  gap: 1em;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.form-button {
  font-size: 0.8em;
  padding: 0.8em 2em;
  width: 8rem;
  border: none;
  border-radius: 5px;
  letter-spacing: 2px;
  font-weight: 700;
  font-family: "Mallanna";
  color: white;
}

.submit-button {
  background-color: var(--bg-green);
}

.submit-button:hover {
  cursor: pointer;
  background-color: #5b8f8d;
  transition: background-color ease-in 150ms;
}

.reset-button {
  background-color: rgb(185, 80, 80);
}

.reset-button:hover {
  cursor: pointer;
  background-color: rgb(184, 112, 112);
  transition: background-color ease-in 150ms;
}

.disabled-button {
  background-color: gray;
}

.disabled-button:hover {
  cursor: auto;
}
